<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotalArr: [],
      svgLineTotal: 0,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    async nLinesTimingChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        totalCount = 241,
        xPadding = 0.3,
        xTicks = [],
      } = {}
    ) {
      this.duration = duration;

      const curve = d3[curveName];
      const X = data.map((d) => d.label);
      const yMaxArr = [
        d3.max(data, (d) => d.value1),
        d3.max(data, (d) => d.value2),
        d3.max(data, (d) => d.value3),
      ];
      const yMinArr = [
        d3.min(data, (d) => d.value1),
        d3.min(data, (d) => d.value2),
        d3.min(data, (d) => d.value3),
      ];

      console.log(data);

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [d3.min(yMinArr), d3.max(yMaxArr)];
      xRange = [
        marginLeft,
        ((width - marginRight - marginLeft) * data.length) / totalCount +
          marginLeft,
      ];
      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);
      const yMagnify = d3.scaleLinear().domain(yRange).range(yDomain); //计算y轴坐标和value对应关系 yMagnify(y)=>value

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //画网格
      const drawGrid = () => {
        const yTick = [1, 2, 3, 4, 5, 6];
        const xTick = [1, 2, 3, 4, 5];
        const yTickSpace =
          (height - marginTop - marginBottom) / (yTick.length - 1);
        const xTickSpace =
          (width - marginLeft - marginRight) / (xTick.length - 1);
        const rowLine = svg.append("g").attr("class", "y_tick_group");
        rowLine
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
          .attr("class", "y_tick")
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 1)
          .transition()
          .duration(300)
          .attr("x2", (d, i) => width - marginRight)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);
        rowLine
          .selectAll("text")
          .data(yTick)
          .enter()
          .append("text")
          .attr("class", "y_tick_text")
          .attr("fill", "currentColor")
          .text((d, i) => yMagnify(marginTop + yTickSpace * i).toFixed(2))
          .attr("x", marginLeft - 5)
          .attr("y", (d, i) => marginTop + yTickSpace * i)
          .attr("dy", "0.5em")
          .attr("text-anchor", "end")
          .attr("opacity", 0)
          .transition()
          .duration(300)
          .attr("opacity", 1);

        const columnLine = svg.append("g").attr("class", "x_tick_group");
        columnLine
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft + xTickSpace * i)
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => height - marginBottom)
          .attr("class", "x_tick")
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 1)
          .transition()
          .duration(300)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);

        if (xTicks.length === 0) {
          xTicks = [X[0], X[parseInt(X.length / 2)], X[X.length - 1]];
        }
        columnLine
          .selectAll("text")
          .data(xTicks)
          .enter()
          .append("text")
          .attr("class", "x_tick_text")
          .attr("fill", "currentColor")
          .text((d, i) => xTicks[i])
          .attr(
            "x",
            (d, i) =>
              marginLeft +
              xTickSpace * i * ((xTick.length - 1) / (xTicks.length - 1))
          )
          .attr("y", height - marginBottom)
          .attr("dy", "1.5em")
          .attr("text-anchor", (d, i) => {
            if (i == 0) {
              return "start";
            } else if (i == xTicks.length - 1) {
              return "end";
            } else {
              return "middle";
            }
          })
          .attr("opacity", 0)
          .transition()
          .duration(300)
          .attr("opacity", 1);
      };
      drawGrid();

      //画柱子
      const drawLine = () => {
        const lineData = [
          data.map((d) => d.value1),
          data.map((d) => d.value2),
          data.map((d) => d.value3),
        ];
        const lineGroup = svg.append("g").attr("class", "line_group");
        for (let j = 0; j < lineData.length; j++) {
          const Y = lineData[j];
          const pathLine = d3
            .line()
            .curve(curve)
            .x((i) => xScale(X[i]))
            .y((i) => yScale(Y[i]));

          const svgLine = lineGroup
            .append("path")
            .attr("fill", "none")
            .attr("class", `line line${j + 1}`)
            .attr("stroke", "black")
            // .attr("stroke-width", "1")
            .attr("d", pathLine(d3.range(Y.length)));
          const svgLineTotalLength = svgLine.node().getTotalLength();
          this.svgLineTotalArr[j] = svgLineTotalLength;
          svgLine
            .attr(
              "stroke-dasharray",
              svgLineTotalLength + "," + svgLineTotalLength
            )
            .attr("stroke-dashoffset", svgLineTotalLength)
            .transition()
            .duration(duration)
            .ease(d3[ease])
            .attr("stroke-dashoffset", 0);
        }
      };
      drawLine();

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
