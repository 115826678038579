<template>
  <div id="northFundFlowChart">
    <div class="pages hidden" id="northFundFlowChartPages">
      <page-title id="northFundFlowChartText" ref="refPageTitle"></page-title>
      <div class="page_date">{{ updateDate }}</div>

      <div :class="{ none: !isFlowVisible }" ref="refFundChartHolder" class="page_content">
        <!-- <div class="data_date">{{ dataDate }}</div> -->
        <chart-legend ref="refFundChartLegend" id="fundChartLegend" class="common_legend fund_legend" />
        <line-chart ref="refFundChart" id="fundChart" class="trend_chart" />
        <n-lines-timing-chart ref="refFundTimeChart" id="fundTimeChart" class="trend_chart" />
        <div class="fund_flow_txt" v-if="flowDirection == '北向'">
          <div class="ff_row">
            <span>沪股通</span>{{ flowPeriod }}净买额<b>{{ fSH1 }}亿</b>
          </div>
          <div class="ff_row">
            <span>深股通</span>{{ flowPeriod }}净买额<b>{{ fSZ1 }}亿</b>
          </div>
          <div class="ff_row">
            <span>北向资金</span>{{ flowPeriod }}净买额<b>{{ fA1 }}亿</b>
          </div>
          <div class="ff_row ff_note">注：北向资金是沪股通与深股通的资金加总</div>
        </div>
        <div class="fund_flow_txt" v-if="flowDirection == '南向'">
          <div class="ff_row">
            <span>港股通(沪)</span>{{ flowPeriod }}净买额<b>{{ fSH1 }}亿</b>
          </div>
          <div class="ff_row">
            <span>港股通(深)</span>{{ flowPeriod }}净买额<b>{{ fSZ1 }}亿</b>
          </div>
          <div class="ff_row">
            <span>南向资金</span>{{ flowPeriod }}净买额<b>{{ fA1 }}亿</b>
          </div>
          <div class="ff_row ff_note">注：南向资金是港股通(沪)与港股通(深)的资金加总</div>
        </div>
      </div>

      <div :class="{ none: !isShareRateVisible }" ref="refShareRateChartHolder" class="page_content">
        <chart-legend ref="refShareRateLegend" id="shareRateLegend" class="common_legend share_rate_legend" />
        <double-y-lines-chart ref="refShareRateChart" id="shareRateChart" customClass="double_line" />
      </div>

      <!--北向十大成交股-->
      <div :class="{ none: !isFundFlowTop10StockVisible }" ref="refTop10StockChartHolder" class="page_content">
        <ul class="simple_summary" v-if="Object.keys(simpleData).length > 0">
          <li>
            <span
              >上证指数<b :style="{ color: simpleData['上证指数'].indexOf('-') !== -1 ? '#39b24e' : '#ff3b30' }">{{
                simpleData["上证指数"]
              }}</b></span
            >
            <span
              >深证指数<b :style="{ color: simpleData['深证成指'].indexOf('-') !== -1 ? '#39b24e' : '#ff3b30' }">{{
                simpleData["深证成指"]
              }}</b></span
            >
          </li>
          <li>
            <span
              >创业板指<b :style="{ color: simpleData['创业板指'].indexOf('-') !== -1 ? '#39b24e' : '#ff3b30' }">{{
                simpleData["创业板指"]
              }}</b></span
            >
            <span
              >中小100<b :style="{ color: simpleData['中小100'].indexOf('-') !== -1 ? '#39b24e' : '#ff3b30' }">{{
                simpleData["中小100"]
              }}</b></span
            >
          </li>
        </ul>
        <table-bar-chart ref="refTop10StockChart" id="top10StockChart" customClass="table_bar_chart" />
      </div>

      <!--近五日北向资金流入-->
      <div :class="{ none: !isHistoryDailyVisible }" ref="refHistoryDailyHolder" class="page_content">
        <chart-legend ref="refHistoryDailyLegend" id="HistoryDailyLegend" class="common_legend history_daily_legend" />
        <bar-line-Chart ref="refHistoryDailyChart" id="historyDailyChart" customClass="history_daily_chart" />
      </div>

      <!--北向资金净流入summary-->
      <div :class="{ none: !isFundFlowSummaryVisible }" ref="refFundFlowSummaryHolder" class="page_content">
        <div :class="{ sign_data: true, num_positive: signData > 0, num_negative: signData < 0 }">
          {{ signDataTxt }}
        </div>
        <bidding-Chart ref="refFundFlowSummary" id="fundFlowSummary" customClass="fund_flow_summary_chart" />
      </div>

      <!--北向资金净流入top10-->
      <div :class="{ none: !isFundFlowTop10IndustryVisible }" ref="refTop10IndustryChartHolder" class="page_content">
        <table-bar-chart
          ref="refTop10IndustryChart"
          id="top10IndustryChart"
          customClass="table_bar_chart table_flow_chart"
        />
      </div>

      <!--收益榜，占比榜，占比异动榜等排名表格-->
      <div :class="{ none: !isRankVisible }" ref="refRankChartHolder" class="page_content">
        <table-rank-chart ref="refRankChart" id="RankChart" customClass="table_bar_chart table_flow_chart" />
      </div>

      <!--沪股通资金概况-->
      <div :class="{ none: !isLongHuSummaryVisible }" ref="refLongHuSummaryHolder" class="page_content">
        <chart-legend
          ref="refLongHuSummaryLegend"
          id="longHuSummaryLegend"
          class="common_legend honghu_summary_legend"
        />
        <double-y-bar-line-Chart
          ref="refLongHuSummaryChart"
          id="longHuSummaryChart"
          customClass="longhu_summary_chart"
        />
        <ul class="longhu_summary">
          <li>
            <span>总成交额</span><b>{{ longHuSummary.fTotleAmout }}亿元</b>
          </li>
          <li>
            <span>买入成交额</span><b>{{ longHuSummary.fBuyAmout }}亿元</b>
          </li>
          <li>
            <span>卖出成交额</span><b>{{ longHuSummary.fSellAmout }}亿元</b>
          </li>
          <li>
            <span>总成交数</span><b>{{ longHuSummary.fTotleNum }}万股</b>
          </li>
          <li>
            <span>买入成交数</span><b>{{ longHuSummary.fBuyNum }}万股</b>
          </li>
          <li>
            <span>卖出成交数</span><b>{{ longHuSummary.fSellNum }}万股</b>
          </li>
          <li>
            <span>每日余额</span><b>{{ longHuSummary.fDailyAmt }}亿元</b>
          </li>
          <li>
            <span>每日额度余额</span><b>{{ longHuSummary.fDailyQuota }}%</b>
          </li>
        </ul>
      </div>

      <!--沪股通资金实时-->
      <div :class="{ none: !isLongHuRealSummaryVisible }" ref="refLongHuRealSummaryHolder" class="page_content">
        <table-chart ref="refLongHuRealSummary" id="LongHuRealSummary" customClass="common_table_chart" />
      </div>

      <!--沪股通涨幅，跌幅top10-->
      <div ref="refLongHuRealHolder" v-if="isLongHuRealVisible">
        <table-chart ref="refLongHuReal" id="LongHuReal" customClass="common_table_chart longhu_real_table" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是northFundFlowChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import lineChart from "@/components/lineChart/index.vue";
import doubleYLinesChart from "@/components/marketChart/doubleYLinesChart.vue";
import nLinesTimingChart from "@/components/marketChart/nLinesTimingChart.vue";
import tableBarChart from "@/components/marketChart/tableBarChart.vue";
import tableRankChart from "@/components/marketChart/tableRankChart.vue";
import biddingChart from "@/components/marketChart/biddingChart.vue";
import tableChart from "@/components/tableChart/index.vue";
import doubleYBarLineChart from "@/components/marketChart/doubleYBarLineChart.vue";
import barLineChart from "@/components/marketChart/barLineChart.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      isFlowVisible: false, //流入流出图表
      flowPeriod: "",
      flowDirection: "",
      // dataDate: "", //数据日期，只在当日有
      fSH1: "", //沪股通
      fSH2: "", //沪股通 历史
      fSZ1: "", //深股通
      fSZ2: "", //深股通 历史
      fA1: "", //加总
      fA2: "", //加总 历史
      longHuSummary: {
        fBuyAmout: 0,
        fBuyNum: 0,
        fDailyAmt: 0,
        fSellAmout: 0,
        fSellNum: 0,
        fTotleAmout: 0,
        fTotleNum: 0,
      },
      simpleData: {},
      updateDate: "", //数据更新日期
      signData: "", //右上角数字，用来决定class
      signDataTxt: "", //右上角数字，显示用

      isShareRateVisible: false, //持股占比图表
      isFundFlowTop10StockVisible: false, //top10个股
      isFundFlowSummaryVisible: false, //北向资金净流入summary
      isFundFlowTop10IndustryVisible: false, //北向资金净流入top10行业
      isHistoryDailyVisible: false, //近五日流入
      isRankVisible: false, //排行榜图表
      isLongHuSummaryVisible: false, //沪深股summary
      isLongHuRealSummaryVisible: false, //沪深股实时summary
      isLongHuRealVisible: false, //沪深股涨跌幅top10
    };
  },
  components: {
    pageTitle,
    chartLegend,
    lineChart,
    doubleYLinesChart,
    nLinesTimingChart,
    tableBarChart,
    tableRankChart,
    biddingChart,
    tableChart,
    doubleYBarLineChart,
    barLineChart,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        this.$refs.refPageTitle.hide();
        await sleep(200);
        this.isFlowVisible = false;
        this.isShareRateVisible = false;
        this.isFundFlowSummaryVisible = false;
        this.isFundFlowTop10IndustryVisible = false;
        this.isFundFlowTop10StockVisible = false;
        this.isHistoryDailyVisible = false;
        this.isRankVisible = false;
        this.isLongHuSummaryVisible = false;
        this.isLongHuRealSummaryVisible = false;
        this.isLongHuRealVisible = false;
        $("#northFundFlowChartPages").addClass("hidden");
        this.updateDate = "";
        this.signData = "";
        this.signDataTxt = "";
        this.simpleData = {};
        resolve();
      });
    },
    async show(commandData) {
      $("#northFundFlowChartPages").removeClass("hidden");
      this.$refs.refPageTitle.show(commandData);

      await sleep(300);

      if (commandData.type == "flow") {
        this.handleFlow(commandData);
      } else if (commandData.type == "shareRate") {
        this.handleShareRate(commandData);
      } else if (
        ["fundFlowTop10Stock", "stockHoldList", "stockBuildUp", "capitalMainFlow", "capitalDetail"].indexOf(
          commandData.type
        ) > -1
      ) {
        this.handleTop10Stock(commandData);
      } else if (commandData.type == "fundFlowIndustry") {
        this.handleFundFlowIndustry(commandData);
      } else if (["holderIncomeRank", "holdRatio", "holdVary"].indexOf(commandData.type) > -1) {
        this.handleRankChart(commandData);
      } else if (["historyDaily"].indexOf(commandData.type) > -1) {
        this.handleHistoryDaily(commandData);
      } else if (["longHuSummary"].indexOf(commandData.type) > -1) {
        this.handleLongHuSummary(commandData);
      } else if (["longHuReal"].indexOf(commandData.type) > -1) {
        this.handleLongHuReal(commandData);
      }
    },
    //流入流出图表
    handleFlow(commandData) {
      this.isFlowVisible = true;
      const data = commandData.data.data;
      this.flowPeriod = commandData.flowPeriod;
      this.flowDirection = commandData.flowDirection;
      this.fSH1 = data.fSH1.toFixed(2);
      this.fSH2 = data.fSH2.toFixed(2);
      this.fSZ1 = data.fSZ1.toFixed(2);
      this.fSZ2 = data.fSZ2.toFixed(2);
      this.fA1 = data.fA1.toFixed(2);
      this.fA2 = data.fA2.toFixed(2);

      this.$nextTick(async () => {
        this.ffRow = anyElement({
          targets: document.querySelectorAll(".ff_row"),
          isSplitTxt: false,
          duration: 1100,
          delay: 160,
          easing: "easeOutExpo",
          inEffect: "topIn",
          outEffect: "leftOutBig",
        });
        this.ffRow.runIn();
        if (commandData.flowPeriod == "当日") {
          this.renderfundTimeChart(commandData);
        } else {
          this.renderfundChart(commandData);
        }
      });
    },
    renderfundTimeChart(commandData) {
      $("#fundChart").addClass("none");
      $("#fundTimeChart").removeClass("none");
      //展示legend
      this.$refs.refFundChartLegend.createLegend({
        legend: commandData.data.legend,
        legendType: "line",
        colors: ["#FF3DB1", "#1F9AFC", "#fcaa05"],
      });
      let width = this.$refs.refFundChartHolder.clientWidth;
      const xTicksObj = {
        北向: ["09:30", "10:30", "11:30/13:00", "14:00", "15:00"],
        南向: ["09:00", "10:32", "13:04", "14:36", "16:10"],
      };
      const totalCountObj = {
        北向: 240,
        南向: 370,
      };
      this.$refs.refFundTimeChart.nLinesTimingChart(commandData.data.data.vIncomeKline, {
        width: width,
        height: width * 0.42,
        marginLeft: 100,
        marginRight: 20,
        marginTop: 40,
        marginBottom: 50,
        duration: 1000,
        delay: 40,
        ease: "easeQuadOut",
        totalCount: totalCountObj[commandData.flowDirection],
        xTicks: xTicksObj[commandData.flowDirection],
      });
      this.updateDate = commandData.data.date;
    },
    renderfundChart(commandData) {
      $("#fundTimeChart").addClass("none");
      $("#fundChart").removeClass("none");
      //展示legend
      this.$refs.refFundChartLegend.createLegend({
        legend: commandData.data.legend,
        legendType: "line",
        colors: ["#FF3DB1", "#1F9AFC", "#fcaa05"],
      });
      let width = this.$refs.refFundChartHolder.clientWidth;
      this.$refs.refFundChart.LineChart(commandData.data.data.vIncomeKline, {
        label: (d) => d.label,
        width: width,
        height: width * 0.42,
        marginLeft: 100,
        marginRight: 20,
        marginTop: 20,
        marginBottom: 60,
        duration: 2000,
        delay: 0,
        xTickNumber: 3,
        yTickNumber: 4,
        ease: "easeQuadOut",
        numberSuffix: "",
        isShowTxtTips: false,
        demicalDigit: 2,
      });
      this.updateDate = commandData.data.date;
    },

    //占比图表
    handleShareRate(commandData) {
      this.isShareRateVisible = true;
      this.$nextTick(async () => {
        this.renderShareRateChart(commandData);
      });
    },
    renderShareRateChart(commandData) {
      this.$refs.refShareRateLegend.createLegend({
        legend: commandData.data.legend,
        legendType: "line",
        colors: ["#fcaa05", "#1F9AFC"],
      });
      let width = this.$refs.refShareRateChartHolder.clientWidth;
      this.$refs.refShareRateChart.doubleYLinesChart(commandData.data.data, {
        width: width,
        height: width * 0.73,
        marginLeft: 82,
        marginRight: 142,
        marginTop: 40,
        marginBottom: 60,
        duration: 2000,
        delay: 40,
        ease: "easeQuadOut",
      });
      this.updateDate = commandData.data.date;
    },

    //深股通十大成交股
    handleTop10Stock(commandData) {
      this.isFundFlowTop10StockVisible = true;
      this.$nextTick(async () => {
        if (["capitalMainFlow", "capitalDetail"].indexOf(commandData.type) !== -1 && commandData.data?.simpleData) {
          this.renderMarketText(commandData);
        }
        this.renderTop10StockChart(commandData);
      });
    },
    renderMarketText(commandData) {
      this.simpleData = { ...commandData.data.simpleData };
      this.summary = anyElement({
        targets: document.querySelectorAll(".simple_summary li"),
        isSplitTxt: false,
        duration: 1200,
        delay: 60,
        easing: "easeOutExpo",
        inEffect: "rightInBig",
        outEffect: "leftOutBig",
      });
      this.summary.runIn();
    },
    renderTop10StockChart(commandData) {
      let width = this.$refs.refTop10StockChartHolder.clientWidth;
      this.$refs.refTop10StockChart.tableBarChart(commandData.data.data, {
        width: width,
        height: ["capitalMainFlow", "capitalDetail"].indexOf(commandData.type) !== -1 ? width * 0.68 : width * 0.78,
        marginLeft: 150,
        marginRight: 240,
        marginTop: ["capitalMainFlow", "capitalDetail"].indexOf(commandData.type) !== -1 ? 70 : 80,
        marginBottom: 0,
        duration: 1000,
        delay: 100,
        ease: "easeQuad",
        isNegativeSameDirection: true,
        yPadding: ["capitalMainFlow", "capitalDetail"].indexOf(commandData.type) !== -1 ? 0.2 : 0.3,
        numberPosition: "inside",
        value1Suffix: commandData.data.value1Suffix,
        value2Suffix: commandData.data.value2Suffix,
        title: commandData.data.title,
      });
      this.updateDate = commandData.data.date;
    },

    //北向资金summary
    handleFundFlowIndustry(commandData) {
      if (commandData.flowDirection == "summary") {
        this.handleFundFlowSummary(commandData);
      } else {
        this.handleTop10Industry(commandData);
      }
    },
    handleFundFlowSummary(commandData) {
      this.isFundFlowSummaryVisible = true;
      this.$nextTick(async () => {
        this.renderFundFlowSummary(commandData);
      });
    },
    renderFundFlowSummary(commandData) {
      let width = this.$refs.refFundFlowSummaryHolder.clientWidth;
      this.$refs.refFundFlowSummary.northFundsIn(commandData.data.data, {
        width: width,
        height: width * 0.74,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 50,
        marginBottom: 80,
        duration: 500,
        delay: 100,
        ease: "easeBack",
        xPadding: 0.5,
        valueSuffix: "亿",
        title: ["净流入top3", "净流出top3"],
      });
      this.signData = commandData.data.sum;
      this.signDataTxt = commandData.data.sum + "亿";
      this.updateDate = commandData.data.date;
    },
    //北向资金净流入
    handleTop10Industry(commandData) {
      this.isFundFlowTop10IndustryVisible = true;
      this.$nextTick(async () => {
        this.renderTop10IndustryChart(commandData);
      });
    },
    renderTop10IndustryChart(commandData) {
      let width = this.$refs.refTop10IndustryChartHolder.clientWidth;
      this.$refs.refTop10IndustryChart.tableBarChart(commandData.data.data, {
        width: width,
        height: width * 0.78,
        marginLeft: 240,
        marginRight: 200,
        marginTop: 60,
        marginBottom: 0,
        duration: 1000,
        delay: 100,
        ease: "easeQuad",
        isNegativeSameDirection: true,
        yPadding: 0.3,
        numberPosition: "inside",
        value1Suffix: commandData.data.value1Suffix,
        value2Suffix: commandData.data.value2Suffix,
        title: commandData.data.title,
      });
      this.updateDate = commandData.data.date;
    },

    //各种rank榜单
    handleRankChart(commandData) {
      this.isRankVisible = true;
      this.$nextTick(async () => {
        this.renderRankChart(commandData);
      });
    },
    renderRankChart(commandData) {
      let width = this.$refs.refRankChartHolder.clientWidth;
      this.$refs.refRankChart.tableRankChart(commandData.data.data, {
        width: width,
        height: width * 0.74,
        marginLeft: 90,
        marginRight: 200,
        marginTop: 50,
        marginBottom: 0,
        duration: 1000,
        delay: 100,
        ease: "easeQuad",
        isNegativeSameDirection: true,
        yPadding: 0.3,
        numberPosition: "inside",
        valueSuffix: "%",
        title: commandData.data.title,
      });
      this.updateDate = commandData.data.date;
    },

    //近五日北向资金流入
    handleHistoryDaily(commandData) {
      this.isHistoryDailyVisible = true;
      this.$nextTick(async () => {
        this.renderHistoryDaily(commandData);
      });
    },
    renderHistoryDaily(commandData) {
      this.$refs.refHistoryDailyLegend.createLegend({
        legend: commandData.data.legend,
        legendType: "line",
        legendType: ["bar", "line", "line"],
        colors: ["#178CEA", "#fcaa05", "#07d634"],
      });
      let width = this.$refs.refHistoryDailyHolder.clientWidth;
      this.$refs.refHistoryDailyChart.barLineChart(commandData.data.data, {
        width: width,
        height: width * 0.7,
        marginLeft: 130,
        marginRight: 20,
        marginTop: 50,
        marginBottom: 60,
        duration: 900,
        delay: 100,
        ease: "easeQuad",
        yPadding: 0.3,
      });
      this.updateDate = commandData.data.date;
    },

    //沪股通资金概况
    handleLongHuSummary(commandData) {
      this.isLongHuSummaryVisible = true;
      this.$nextTick(async () => {
        this.renderLongHuSummary(commandData);
      });
    },
    renderLongHuSummary(commandData) {
      this.$refs.refLongHuSummaryLegend.createLegend({
        legend: commandData.data.legend,
        legendType: "line",
        colors: ["#fcaa05", "#1F9AFC"],
      });
      let width = this.$refs.refLongHuSummaryHolder.clientWidth;
      this.$refs.refLongHuSummaryChart.doubleYBarLineChart(commandData.data.data, {
        width: width,
        height: width * 0.47,
        marginLeft: 87,
        marginRight: 147,
        marginTop: 40,
        marginBottom: 60,
        duration: 1500,
        delay: 20,
      });
      this.longHuSummary = { ...commandData.data.summary };
      this.updateDate = commandData.data.date;
      this.summary = anyElement({
        targets: document.querySelectorAll(".longhu_summary li"),
        isSplitTxt: false,
        duration: 1200,
        delay: 60,
        easing: "easeOutExpo",
        inEffect: "rightInBig",
        outEffect: "leftOutBig",
      });
      this.summary.runIn();
    },

    //========沪股通summary, 沪股通涨跌top10=========
    handleLongHuReal(commandData) {
      if (commandData.market == "summary") {
        this.handleLongHuRealSummary(commandData);
      } else {
        this.handleLongHuRealList(commandData);
      }
    },

    //沪股通summary
    handleLongHuRealSummary(commandData) {
      this.isLongHuRealSummaryVisible = true;
      this.$nextTick(async () => {
        this.renderLongHuRealSummary(commandData);
      });
    },
    renderLongHuRealSummary(commandData) {
      let width = this.$refs.refLongHuRealSummaryHolder.clientWidth;
      this.$refs.refLongHuRealSummary.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col2Suffix: "亿元",
      });
      this.updateDate = commandData.data.date;
    },
    //沪股通涨跌top10
    handleLongHuRealList(commandData) {
      this.isLongHuRealVisible = true;
      this.$nextTick(async () => {
        this.renderLongHuReal(commandData);
      });
    },
    renderLongHuReal(commandData) {
      let width = this.$refs.refLongHuRealHolder.clientWidth;
      this.$refs.refLongHuReal.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col2Suffix: "",
        col3Suffix: "%",
      });
      this.updateDate = commandData.data.date;
    },
  },
  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
